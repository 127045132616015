@import "~video.js/src/css/private-variables";

$primary-background-color: #1a1a1a;
$secondary-background-color: lighten($primary-background-color, 33%);

@import "~video.js/src/css/video-js";

@import "~@/lib/videojs-chapter-markers/scss/chapter-markers.scss";

.vjs-chapters-button {
  .vjs-menu {
    width: 15em;
    left: -5.5em;

    &-item {
      padding: .2em .3em;
    }
  }
}
