.video-js.vjs-chapter-markers {
  .vjs-marker-bar {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
  }

  .vjs-marker-point {
    position: absolute;
    top: -0.25em;
    padding: 0.25em;
    margin-left: -0.5em;

    &:before {
      content: '';
      display: block;
      width: 0.5em;
      height: 0.3em;
      background: white;
      border-radius: 0.3em;
    }

    .vjs-time-tooltip.vjs-marker-point-tip {
      top: auto;
      bottom: 1.2em;
      max-width: 220px;
      width: max-content;
      font-size: 12px;
      float: none;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
