@import "~vuetify/src/styles/settings/colors";

.v-application {
  a {
    text-decoration: none;
  }
}

.v-input {
  &--required {
    .v-label:after {
      content: '*';
      padding-left: 4px;
      color: map-get($red, accent-2);
    }

    .v-label--active:after {
      color: inherit;
    }
  }

  &--nowrap {
    .v-select__selections {
      flex-wrap: nowrap !important;
    }
  }
}

.v-dialog {
  overflow-y: visible !important;
}

.v-card {
  &--focused-glow {
    animation: 2.5s ease card-focused-glow;
  }

  @at-root {
    .theme--dark &--focused-glow {
      --shadow-focus-color: var(--v-lines-lighten2);
    }
  }
}

@keyframes card-focused-glow {
  50% {
    box-shadow: 0 0 10px 5px var(--shadow-focus-color, var(--v-accent-lighten1));
  }
}
