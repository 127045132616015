.header-logo {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: normal;
  text-transform: uppercase;
  color: var(--v-primary-base);
  line-height: 1;

  &__divider {
    display: inline-flex;
    width: 1px;
    height: 18px;
    background-color: #fff;
    margin: 0 9px;
  }

  &__suffix {
    color: #fff;
  }
}

.header-locale {
  display: flex;
  align-items: center;

  &__btn {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 1;
    color: #505050;

    &--active {
      color: #fff;
    }
  }

  &__divider {
    display: inline-flex;
    width: 1px;
    height: 16px;
    background-color: var(--v-primary-base);
    margin: 0 7px;
  }
}
