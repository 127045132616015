.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, .7);

    &--dark {
        background-color: rgba(0, 0, 0, .7);
    }
}
