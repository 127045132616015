.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-line-clamp {
  display: -webkit-box;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.line-clamp {
  @for $i from 1 through 10 {
    &-#{$i} {
      -webkit-line-clamp: $i !important;
    }
  }
}

.text-break-word {
  word-break: break-word;
}

.text-pre-line {
  white-space: pre-line !important;
}

.font-size-inherit {
  font-size: inherit !important;
}

.color-inherit {
  color: inherit !important;
}
