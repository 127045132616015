.fill-width {
  width: 100%;
}

.full-height {
  height: 100vh;
}

.full-width {
  height: 100vw;
}

.clear-both {
  clear: both;
}
