$cursors: (pointer, help);

.cursor {
  @each $cursor in $cursors {
    &-#{$cursor} {
      cursor: $cursor;
    }
  }
}

.cursor-hover {
  .v-image {
    transition: .2s;
  }

  &:hover {
    .v-image {
      opacity: .8;
    }
  }
}
