.background-none {
  background: none !important;
}

.theme--light {
  .background-theme {
    background: #fff !important;
  }
}

.theme--dark {
  .background-theme {
    background: #121212 !important;
  }
}